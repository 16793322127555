<template>
    <modal
        class="video-intro-modal"
        :adaptive="true"
        name="video-intro-modal"
        :height="isMobileScreen ? '100%' : '85%'"
        :width="isMobileScreen ? '100%' : '85%'"
        :pivot-y="isMobileScreen ? 0 : 0.5"
    >
        <div class="close-modal" @click="$modal.hide('video-intro-modal')">
            <img src="@assets/img/icons/close.svg" alt="X">
        </div>
        <div class="video-wrapper">
            <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AyQM6_01P6U?modestbranding=1&rel=0&fs=0&showinfo=0&autoplay=1"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        </div>
    </modal>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "VideoIntroModal",
    computed: {
        ...mapState({
            isMobileScreen: state => state.Application.isMobileScreen
        })
    }
}
</script>

<style lang="scss" scoped>
.video-intro-modal {
    z-index: 8999999;

    .close-modal {
        position: absolute;
        right: 30px;
        top: 50%;
        cursor: pointer;
        z-index: 3;
        transform: translateY(-50%);

        @media(max-width: $sm) {
            top: 30px;
            right: 20px;

            img {
                width: 30px;
            }
        }
    }

    /deep/ .vm--overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    /deep/ .vm--modal {
        background-color: black;
    }

    .video-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;

        @media(max-width: $md) {
            border-radius: 0;
        }
    }
}
</style>
